
html {
    height: 100vh;
    padding: 0;
    margin: 0;
    --primary-color: #00517a;
    --secondary-bgcolor: rgba(0,0,0, 0.05);

    background-color: var(--primary-color);

}

body {
    height: 100%;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
}

::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
}

::-webkit-scrollbar-track {
    background-color: #0000002c; /* Color de fondo del scrollbar */
    border-radius: 3px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background-color: #ffffffc7; /* Color del control del scrollbar */
    border-radius: 3px; /* Borde redondeado */
}

/* Estilo de las barras de desplazamiento cuando se arrastran */
::-webkit-scrollbar-thumb:active {
    background-color: #555;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }